import React, { useState } from "react"
import styled from "@emotion/styled"
import scrollTo from 'gatsby-plugin-smoothscroll';

import Logo from '../images/logo.inline.svg'
import Phone from '../images/icon-phone.inline.svg'
import Burger from '../images/icon-menu.inline.svg'

const MobileMenuWrapper = styled.div`

  margin: 0 auto;
  padding: .5em 2em 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 800px) {
    padding: .5em 3em 0;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

const LogoWrapper = styled.div`
  svg {
    height: 64px;
  }
`
const PhoneWrapper = styled.a`
  svg {
    width: 36px;
    fill: #edce76;
  }
`

const MenuToggle = styled.a`

  cursor: pointer;

  svg {
    width: 36px;
    fill: #edce76;
  }
`

const FsMobileNavMenu = styled.div`
  background: #000000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  visibility: ${props => (props.open ? "hidden" : "visible")};
  opacity: ${props => (props.open ? "0" : "1")};
  transition: all .2s ease;

  z-index: 999;

  display: flex;
  flex-direction: column;

  padding: 2em;

  svg {
    height: 64px;
    width: auto;
  }
`

const FullScreenWrapper = styled.div`
`

const MobileNavMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  padding-bottom: 35vh;
`

const MenuItem = styled.li`

`

const MenuItemLink = styled.a`
  color: rgba(255,255,255,.65);
  /* color: #a7a5a4; */
  text-shadow: 0px 0px 34px rgba(0,0,0,.9)
`


const MobileMenu = () => {

  const [navMenuOpen, setNavMenuOpen] = useState(false)

  return (
  <div>
    <MobileMenuWrapper>
      <PhoneWrapper href="tel:9195559183">
        <Phone />
      </PhoneWrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <MenuToggle
        navMenuOpen={navMenuOpen}
        onClick={() => setNavMenuOpen(!navMenuOpen)}
      >
        <Burger />
      </MenuToggle>
    </MobileMenuWrapper>
    <FullScreenWrapper>
      {navMenuOpen ? <FsMobileNavMenu
        onClick={() => setNavMenuOpen(!navMenuOpen)}
        onKeyDown={() => setNavMenuOpen(!navMenuOpen)}
        >
          <MobileNavMenu>
            <MenuItem><MenuItemLink href="https://olivedurham.com"><Logo style={{ height: '92px', width: 'auto', marginBottom: '2em'}}/></MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#menu')} alt="Menu">Menu</MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#menu')} alt="Drinks">Drinks</MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#events')} alt="Live Music">Live Music</MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#catering')} alt="Catering">Catering</MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#about')} alt="About">About</MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#contact')} alt="Contact">Contact</MenuItemLink></MenuItem>
            <MenuItem style={{marginTop: '2em'}}><MenuItemLink href="tel:9195559183" alt="Call Us" style={{display: 'flex', alignItems: 'center'}}><Phone style={{ height: '28px', fill: 'rgba(255,255,255,.65)', marginRight: '1em'}}/>(919) 555-9183</MenuItemLink></MenuItem>
          </MobileNavMenu>
        </FsMobileNavMenu>: <FsMobileNavMenu open>
      </FsMobileNavMenu>}
    </FullScreenWrapper>
  </div>
)}

export default MobileMenu
