import React from "react"
import styled from '@emotion/styled'
import Headroom from 'react-headroom'
import MobileMenu from './mobilemenu'
import scrollTo from 'gatsby-plugin-smoothscroll';

import Logo from '../images/logo.inline.svg'

const MenuWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 2px;
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(black, transparent);
  width: 100vw;
`

const Contain = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 1.5em 0;
`

const FsMenu = styled.div`

  display: none;

  @media (min-width: 1024px) {
    display: block;
    margin: 0 auto;
    max-width: 1280px;
  }
`

const NavMenu = styled.ul`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

const MenuItem = styled.li`

`

const MenuItemLink = styled.button`
  color: rgba(255,255,255,.65);
  /* color: #a7a5a4; */
  text-shadow: 0px 0px 34px rgba(0,0,0,.9);
  text-transform: uppercase;
`





const Header = () => (
  <Headroom pinStart={1200}>
    <MenuWrapper>
      <Contain>
        <FsMenu>
          <NavMenu>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#menu')}>Menu</MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#menu')}>Drinks</MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#events')}>Live Music</MenuItemLink></MenuItem>
            <MenuItem><a href="https://olivedurham.com" alt="Home"><Logo style={{ height: '92px', width: 'auto', margin: '0 2em'}}/></a></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#catering')} alt="Catering">Catering</MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink onClick={() => scrollTo('#about')} alt="About">About</MenuItemLink></MenuItem>
            <MenuItem><MenuItemLink href="mailto:info@olivedurham.com" alt="Contact">Contact</MenuItemLink></MenuItem>
          </NavMenu>
        </FsMenu>
        <MobileMenu />
      </Contain>
    </MenuWrapper>
  </Headroom>
)

export default Header
